/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, Button, Container, Text, Flex, Heading, jsx } from 'theme-ui';
import { useTranslate } from '@arfabrands/gatsby-theme-assembly';

const ErrorNotFoundPage = () => {
  const translate = useTranslate();

  return (
    <Box>
      <Container>
        <Flex
          sx={{
            alignItems: 'center',
            padding: ['3rem 0', '8rem 0'],
            flexDirection: 'column',
            minHeight: '80vh',
            textAlign: 'center',
            maxWidth: '850px',
            margin: 'auto',
          }}
        >
          <Heading as="h1" variant="h1" sx={{ marginBottom: '2rem' }}>
            {translate('error.not_found.title')}
          </Heading>
          <Text sx={{ marginBottom: ['2rem', null, '4rem'] }}>
            {translate('error.not_found.text')}{' '}
            <Link to="/" sx={{ textDecoration: 'underline' }}>
              {translate('error.not_found.text_homepage_link')}
            </Link>
            .
          </Text>
          <Button
            as={Link}
            to="/"
            sx={{
              backgroundColor: 'alizarinCrimson',
              color: 'canary',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: 'alizarinCrimson',
                backgroundColor: 'canary',
              },
            }}
          >
            Home
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default ErrorNotFoundPage;
